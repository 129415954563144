const initialState = { tabbingKey: "1" };

// set Navigation Reducer Redux Function
export const setNavigationReducer = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_KEY":
      return { ...state, tabbingKey: raw.tabbingKey };
    default:
      return state;
  }
};
