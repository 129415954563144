import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat,

} from "@apollo/client";
import { getStorage } from "../utils/secureStorage";
import { createUploadLink } from "apollo-upload-client";
import { mainDomain } from "./axiosCaller";

const authMiddleware = new ApolloLink((operation, forward) => {

  // Add Authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: getStorage("isLogin")
        ? `Bearer ${getStorage("isLogin").getOAuthTokenForVendor.token}`
        : "",
      refresh_token: getStorage("isLogin")
        ? getStorage("isLogin").getOAuthTokenForVendor.refreshToken
        : "",
    },
  }));

  return forward(operation);
});

// Set Node Link
const Node_Link = new createUploadLink({
  //uri: "http://54.212.183.253:5000/graphql",
  uri: "https://akcmsapi.organicplate.org/graphql",
});
// set User Link
const User_Link = new createUploadLink({
  //uri: "http://112.196.108.244:9008/graphql",
  uri: mainDomain + "user-service/graphql",
});

// Set Vertex Link
const Vertex_Link = new HttpLink({
  //uri: "http://112.196.108.244:8081/v1/user/graphql/",
  uri: mainDomain + "user-service/graphql",
})
// set QA_Link
const QA_Link = new HttpLink({
  //uri: "http://112.196.108.244:9007/graphql",
  uri: mainDomain + "qna-service/graphql",
});
// Set Testing_Link
const TESTING_LINK = new createUploadLink({
  uri: "http://54.212.183.253:5000/graphql/",
});
// Set Category Link
const CATEGORY = new createUploadLink({
  //uri: "http://112.196.108.244:9000/graphql",
  uri: mainDomain + "category-service/graphql",
});
// Set Location Link
const LOCATION = new HttpLink({
  //uri: "http://112.196.108.244:9001/graphql/",
  uri: mainDomain + "location-service/graphql",
});
// Set Master Link
const MASTER_KEYWORD = new HttpLink({
  //uri: "http://112.196.108.244:9005/graphql",
  uri: mainDomain + "button-service/graphql",
})
const JASKARAN = new HttpLink({
  //uri: "http://112.196.108.244:9005/graphql",
  uri: "http://10.10.9.12:9008/graphql",
})
// Set Node_client
export const Node_Client = new ApolloClient({
  link: concat(authMiddleware, Node_Link),
  cache: new InMemoryCache()
});
// Set Login_Client
export const Login_Client = new ApolloClient({
  // link: concat(authMiddleware, User_Link),
  link: User_Link,
  cache: new InMemoryCache(),
});
// Set Expert_Client
export const Expert_Client = new ApolloClient({
  link: concat(authMiddleware, Vertex_Link),
  cache: new InMemoryCache(),
});

// Set User_Client
export const User_Client = new ApolloClient({
  link: concat(authMiddleware, User_Link),
  cache: new InMemoryCache(),
});
// Set QA_Client
export const QA_Client = new ApolloClient({
  link: concat(authMiddleware, QA_Link),
  cache: new InMemoryCache(),
});

// Set Testing_Client
export const Testing_Client = new ApolloClient({
  link: concat(authMiddleware, TESTING_LINK),
  cache: new InMemoryCache(),
});

// Set Category Client
export const Category_Client = new ApolloClient({
  link: concat(authMiddleware, CATEGORY),
  cache: new InMemoryCache({
    addTypename: false
  })
});
// Set Location Client
export const Location_Client = new ApolloClient({
  link: concat(authMiddleware, LOCATION),
  cache: new InMemoryCache(),
});

// Set Master Client
export const MasterKeyword_Client = new ApolloClient({
  link: concat(authMiddleware, MASTER_KEYWORD),
  cache: new InMemoryCache(),
})

export const jaskaran_local = new ApolloClient({
  link: JASKARAN,
  cache: new InMemoryCache(),
})



