const initialState = {};

// Set Product Reducer Edit Redux Function
export const setProductReducerEdit = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_INFO":
      return { ...state, info: raw.info, product_id: raw.product_id };

    case "SET_ABOUT":
      return { ...state, about: raw.about };

    case "SET_VARIANTS":
      return { ...state, variants: raw.variants };

    case "SET_TARGET_CROPS":
      return { ...state, targets: raw.targets };

    case "SET_DOSAGE":
      return { ...state, dosages: raw.dosages };

    default:
      return state;
  }
};
