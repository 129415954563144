import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/crm/store/store";
import { ApolloProvider } from "@apollo/client";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Login_Client } from "./crm/utils/clients";

// Defining Options
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

// Rendering Dom
ReactDOM.render(
    <ApolloProvider client={Login_Client}>
      <Provider store={store} >       
          <AlertProvider template={AlertTemplate} {...options}>
            <App />
          </AlertProvider>       
      </Provider>
    </ApolloProvider>,
  document.getElementById("root")
);

reportWebVitals();
