const initialState = {};

// Set Order History Reducer Redux Function
export const setOrderHistoryReducer = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_ORDER_BAR_STATUS":
      return { ...state, order_bar_status: raw.order_bar_status };

    case "SET_OVERVIEW":
      return { ...state, overview: raw.overview };

    case "SET_PAYMENT":
      return { ...state, payment: raw.payment };

    case "SET_INVOICE":
      return { ...state, invoice: raw.invoice };

    case "SET_TRANSACTION":
      return { ...state, transaction: raw.transaction };

    case "SET_SHIPPING":
      return { ...state, shipping: raw.shipping };

    default:
      return state;
  }
};
