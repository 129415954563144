const initialState = {};

// Set Product List Redux Function
export const setProductListReducer = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_ALL":
      return { ...state, all: raw.all };

    case "SET_PUBLISHED":
      return { ...state, published: raw.published };

    case "SET_DRAFTS":
      return { ...state, drafts: raw.drafts };

    case "SET_ARCHIVED":
      return { ...state, archived: raw.archived };

    case "SET_APPROVAL_REQUEST":
      return { ...state, approval_request: raw.approval_request };

    default:
      return state;
  }
};
