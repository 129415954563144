const initialState = {};

// Set Seller Reducer Redux Function
export const setSellerReducer = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_SELLER_LIST":
      return { ...state, seller_list: raw.seller };

    default:
      return state;
  }
};
