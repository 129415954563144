import React, { useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { setStorage } from "./crm/utils/secureStorage";
import "antd/dist/antd.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Location_Client, User_Client } from "./crm/utils/clients";
import { userLogout } from "./crm/store/actions";
import Loader from "./crm/components/Loader";
import { PrivateRoute } from "./crm/utils";
import { getStorage } from "./crm/utils/secureStorage";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";

// Importing the Default layout
const DefaultLayout = React.lazy(() =>
  import("../src/crm/layout/defaultLayout")
);

// Importing Login
const Login = React.lazy(() => import("./crm/views/Login"));
// Declaring Loaders
const loading = <Loader />;

const loginData = getStorage("isLogin");

function App() {
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    loginData?.getOAuthTokenForVendor?.token && getCountryId();
  }, [loginData?.getOAuthTokenForVendor?.token]);

  const GET_INDIA_ID = () => gql`
    {
      getIdOfIndia
    }
  `;

  // Query to get All Countries
  const REFETCH_TOKEN = () => gql`
  query getTokenFromRefreshToken{
      getTokenFromRefreshToken(accessToken: "${loginData?.getOAuthTokenForVendor?.token}", refreshToken: "${loginData?.getOAuthTokenForVendor?.refreshToken}") {
        token
        refreshToken
      }
    }`
  const [checkToken, { data, error }] = useLazyQuery(REFETCH_TOKEN(), {
    client: User_Client
  });

  const logoutAction = () => {
    localStorage.clear();
    window.location.pathname = "/signin";
  };

  useEffect(() => {

    if (error?.graphQLErrors[0]?.extensions?.statusCode === 410 || error?.networkError?.statusCode === 401) {
      alert.info("Someone Logged In From Another Device!!!")
      setTimeout(() => {
        dispatch(userLogout());
        logoutAction();
      }, 500);

    }

  }, [error?.graphQLErrors[0]?.extensions?.statusCode, error?.networkError?.statusCode])


  /** Get Country Id */
  const [getCountryId, { error: countryError }] = useLazyQuery(GET_INDIA_ID(), {
    onCompleted: (data) => {
      setStorage("indiaId", data.getIdOfIndia);
    },
    client: Location_Client,
    fetchPolicy: "network-only",
  });
  console.log("OuterError", countryError?.networkError?.statusCode)

  useEffect(() => {
    if (
      // countryError?.networkError?.statusCode === 410 ||
      countryError?.networkError?.statusCode === 401
    ) {
      checkToken();
    }
    console.log("countryError?.networkError?.statusCode", countryError?.networkError?.statusCode)
  }, [countryError?.networkError?.statusCode])
  // Returning JSX
  return (
    <div className="App">
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/signIn"
              name="Sign In"
              render={(props) => <Login {...props} />}
            />
            <PrivateRoute>
              <Route
                path="/"
                render={(props) => <DefaultLayout {...props} />}
              />
            </PrivateRoute>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
