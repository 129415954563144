import axios from "axios";
import { getStorage } from "../utils/secureStorage";

// Declaring main Domains
export const mainDomain = "https://coreapi.apnikheti.com/v1/";
// const localDomain = "http://10.10.10.33";

/** Token Callback */
const getToken = () => {
  return getStorage("isLogin");
};
// Function Defination for Api
const APICaller = (endpoint, method, body, contentType, token, domain) =>
  axios({
    url: (domain ? domain : mainDomain) + endpoint,
    method: method || "GET",
    data: body,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": contentType || "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
        ? `Bearer ${token}`
        : `Bearer ${getToken().getOAuthTokenForVendor.token}`,
    },
    responseType: "json",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });

export default APICaller;
