import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import rootReducer from './reducers/index';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Declaring Store
const store = createStore(persistedReducer, applyMiddleware(logger));
// const store = createStore(rootReducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const persistor = persistStore(store);

export default store;
