import { combineReducers } from "redux";
import { authReducer } from "./authReducer"
import { setProductReducer } from "./setProductReducer";
import { setCategoryReducer } from "./setCategoryReducer";
import { setDosageReducer } from "./setDosageReducer";
import { setProductListReducer } from "./setProductListReducer";
import { setNavigationReducer } from "./setNavigationReducer";
import { setOrderHistoryReducer } from "./setOrderHistoryReducer";
import { setSellerReducer } from "./setSellerReducer";
import { setCustomerReducer } from "./setCustomerReducer";
import { setProductReducerEdit } from "./setProductReducerEdit";
import { setCategoryReducerEdit } from "./setCategoryReducerEdit";
import { setDosageReducerEdit } from "./setDosageReducerEdit";
import { storeReducer } from "./storeReducer";


//Redux Reducer
const rootReducer = combineReducers({
    isAuthenticate: authReducer,
    product: setProductReducer,
    product_edit: setProductReducerEdit,
    category_edit: setCategoryReducerEdit,
    category: setCategoryReducer,
    dosage: setDosageReducer,
    dosage_edit: setDosageReducerEdit,
    product_list: setProductListReducer,
    tabbing: setNavigationReducer,
    order_history: setOrderHistoryReducer,
    seller: setSellerReducer,
    customer: setCustomerReducer,
    vendor_store: storeReducer,
});

export default rootReducer;