const initialState = {};

// set Dosage Reducer Edit Redux Function
export const setDosageReducerEdit = (state = initialState, { type, raw }) => {
    switch (type) {
        case "SET_PRODUCT":
            return { ...state, productId: raw.productId, };
        case "SET_PRODUCT_TYPE":
            return { ...state, productTypeId: raw.productTypeId, };
        case "SET_STAGE":
            return { ...state, stageId: raw.stageId, };
        case "SET_SUBSTAGE":
            return { ...state, subStageId: raw.subStageId, };
        case "SET_AGE":
            return { ...state, ageId: raw.ageId, };
        default:
            return state;
    }
};
