import React from "react";

// Loader Component
export default function Loader(props) {
  // Returning JSX
  return (
    <>
      <div className="outer-loading-dots">
        <div className="loading-dots">
          <p className="wait-text">
            {props.message ? props.message : "Please wait"}
          </p>
          <span>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
      </div>
    </>
  );
}
