const initialState = {};

// set Customer Reducer Redux Component
export const setCustomerReducer = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_CUSTOMER_LIST":
      return { ...state, customer_list: raw.customer };

    default:
      return state;
  }
};
