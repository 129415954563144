var CryptoJS = require("crypto-js");
var SecureStorage = require('secure-web-storage')
 
var SECRET_KEY = 'VikramSharma';
 
// Secure Storage
 const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
 
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
 
        data = data.toString(CryptoJS.enc.Utf8);
 
        return data;
    }
});
 

// There is no need to stringify/parse you objects before and after storing.
 
// Set Item
export const setStorage = (key,data)=>secureStorage.setItem(key, data);
/*Stores inlocalStorage like:
key => value
"ad36d572..." => "w1svi6n..."*/
 
// Get Item
export const getStorage =(key)=> secureStorage.getItem(key);

// Remove Item
export const removeStorage =(key)=> secureStorage.removeItem(key);
/*SecureStorage.key(id)
returns the hashed version of the key you passed into setItem with the given id*/
 
// Clear Item
export const clearStorage= ()=>secureStorage.clear();
/*Clears all data in the underlining sessionStorage/localStorage.*/
/*The number of entries in the underlining sessionStorage/localStorage.*/

export default secureStorage