//Create Login Action
export const setToken = (payload) => {
  return {
    type: "SET_TOKEN",
    payload: payload,
  };
};

//Logout Action
export const userLogout = () => {
  return {
    type: "USER_LOGOUT",
  };
};

//Set Product
export const setProduct = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Product Edit
export const setProductEdit = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Category
export const setCategory = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

// set Category Edit
export const setCategoryEdit = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Dosage Values
export const setDosage = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};
// set Edit Dosage
export const setDosageEdit = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Product Listing Values
export const setProductList = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Tabbing Key
export const setTabbingKey = (payload) => {
  return {
    type: "SET_KEY",
    raw: payload,
  };
};

//Set Order History
export const setOrderHistory = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Seller
export const setSeller = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

//Set Customer
export const setCustomer = (action, payload) => {
  return {
    type: action,
    raw: payload,
  };
};

export const setStore = (payload) => {
  return {
    type: "SET_STORE",
    raw: payload,
  }
}
