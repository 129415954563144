const initialState = {};

// Set Category Reducer Edit Redux Edit
export const setCategoryReducerEdit = (state = initialState, { type, raw }) => {
  // Switch Statement
  switch (type) {
    case "SET_DOMAIN":
      return { ...state, domainId: raw.domainId };
    case "SET_PARENT":
      return { ...state, parentId: raw.parentId };
    case "SET_CATEGORY":
      return { ...state, categoryId: raw.categoryId };
    default:
      return state;
  }
};
