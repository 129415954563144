import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getStorage } from "../utils/secureStorage";

// Private Route Function
export const PrivateRoute = ({ dispatch, component, ...rest }) => {
  const userData = getStorage("isLogin");
  if (!userData || !userData.getOAuthTokenForVendor.token) {
    return <Redirect to="/signIn" />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};
