import { clearStorage } from "../../utils/secureStorage";

// Redux  authReducer Function
export const authReducer = (state = false, { type, payload }) => {
  // Switch Statement
  switch (type) {
    case "SET_TOKEN":
      return (state = payload);
    case "USER_LOGOUT":
      state = false;
      clearStorage();
     return null
    default:
      return state;
  }
};
